<template>
  <div>
    <b-sidebar
      id="sidebar-image-mssage-card"
      ref="sidebar-image-mssage-card"
      v-model="isShowSidebar"
      aria-labelledby="sidebar-image-mssage-card-header"
      right
      backdrop
      shadow
      no-header
      width="85vw"
    >
      <div class="px-3">
        <b-row class="header-filter">
          <b-col class="text-filter">Image Message Card</b-col>
          <b-col class="text-right">
            <button class="btn-clear" @click.prevent="hide">x</button>
          </b-col>
        </b-row>
        <div class="mt-3">
          <div v-if="isLoading">
            <img
              src="@/assets/icons/loading.svg"
              class="loading"
              alt="loading"
            />
          </div>
          <div v-else>
            <b-row>
              <b-col class="">
                <div class="title-msg">Image Message Card</div>
              </b-col>
              <b-col class="text-right">
                <b-button
                  class="title-msg pointer txt-underline"
                  @click.prevent="onAddResponse()"
                  >Add</b-button
                >
              </b-col>
            </b-row>
            <div v-for="(item, lindex) in imageList" :key="lindex">
              <div class="reply-box p-3">
                <b-row>
                  <b-col class="reply-text"
                    >Image Message Card # {{ lindex + 1 }}</b-col
                  >
                  <b-col class="text-right" v-if="imageList.length > 1">
                    <b-button @click.prevent="onDeleteResponse(item, lindex)">
                      <font-awesome-icon
                        icon="trash-alt"
                        class="pointer"
                        color="#000"
                      />
                    </b-button>
                  </b-col>
                </b-row>
                <div class="w-25">
                  <InputText
                    textFloat="Sort Order"
                    placeholder="Sort Order"
                    type="number"
                    name="sortOrder"
                    v-model="item.sort_order"
                  />
                  <label class="title-image">Image</label>
                  <div
                    v-if="item.image_url"
                    class="panel-bg-file-img mb-2"
                    v-bind:style="{
                      backgroundImage: 'url(' + item.image_url + ')',
                    }"
                  ></div>
                  <div v-else class="panel-bg-file-img mb-2" for="file_upload">
                    <font-awesome-icon
                      icon="plus"
                      color="#FFB300"
                      class="icon-add"
                    />
                    <input
                      type="file"
                      name="name"
                      ref="input"
                      :accept="type[accept].toString()"
                      id="file_upload"
                      v-on:change="(e) => onFileChange(e, lindex)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between shadow align-items-center">
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light rounded-pill"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              block
              variant="light"
              class="text-body rounded-pill btn-main"
              @click.prevent="submit"
            >
              Save
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import ModalLoading from "@/components/modal/ModalLoading";
import ModalAlertError from "@/components/modal/ModalAlertError";

export default {
  components: {
    ModalAlertError,
    ModalLoading,
  },
  props: {
    imageList: {
      required: true,
      type: Array,
    },
    friendGetFriendId: {
      required: true,
      type: [String, Number],
    },
    accept: {
      required: false,
      type: String,
    },
  },
  watch: {
    isShowSidebar(val) {
      if (val) {
        setTimeout(async () => {
          this.isLoading = false;
        }, 1000);
      } else {
        this.isLoading = true;
      }
    },
  },
  data() {
    return {
      file: "",
      value: "",
      type: {
        all: ["image/jpeg", "image/png", "application/pdf", ""],
        file: ["image/jpeg", "image/png", "application/pdf"],
        image: ["image/jpeg", "image/png"],
      },
      isLoading: true,
      isShowSidebar: false,
      modalMessage: "",
      image: "",
      filename: "",
      images: "",
    };
  },
  methods: {
    show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    onAddResponse() {
      let image = {
        id: 0,
        image_url: "",
        sort_order: 0,
        friend_get_friend_id: this.friendGetFriendId,
      };
      image.sort_order = this.imageList.length + 1;
      this.imageList.push(image);
    },
    onFileChange(e, index) {
      // this.errorFile = "";
      const file = e.target.files[0];
      this.filename = file.name;
      var _validFileExtensions = this.type[this.accept];

      if (e.target.files.length) {
        if (_validFileExtensions.indexOf(file.type) < 0) {
          this.$refs.input.value = "";
          this.modalMessage = `fileNotSupport`;
          this.$refs.modalAlertError.show();
        } else if (file.size > 10000000) {
          this.modalMessage = `fileIsTooLarge`;
          this.$refs.modalAlertError.show();
        } else {
          // this.image = URL.createObjectURL(file);
          this.handleChangeFileImage(file, index);
        }
      } else {
        this.$refs.input.value = "";
      }
    },
    handleChangeFileImage: async function (value, index) {
      if (value) {
        this.$refs.modalLoading.show();
        var reader = new FileReader();
        reader.readAsDataURL(value);
        reader.onload = async () => {
          let im = new Image();
          im.src = reader.result;
          im.onload = async () => {
            if (im.width !== im.height) {
              this.$swal("Please upload image with ratio 1:1 !", {
                icon: "info",
              });
            } else {
              var savetoDB = await this.saveImagetoDb(reader.result);
              await this.saveImage(savetoDB, index);
            }
            this.$refs.modalLoading.hide();
          };
        };
      } else {
        this.value = "";
      }
    },
    saveImage: async function (value, index) {
      if (value) {
        this.imageList[index].image_url = value;
      }
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      return data.detail;
    },
    onDeleteResponse(item, index) {
      this.imageList.splice(index, 1);
    },
    submit() {
      this.$emit("sortOrderImage");
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}

::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: 0 1rem;
}
.bg-color {
  background-color: var(--primary-color);
}
.title-msg {
  color: #000;
  font-size: 17px;
}
.btn-outline {
  background-color: var(--theme-secondary-color);
  border-radius: 50px;
  color: var(--primary-color);
}
::v-deep .btn-secondary {
  background-color: #fff;
  border-color: #fff;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.text-error {
  color: red;
}
.border-error {
  border-color: red;
}
::v-deep .b-sidebar#sidebar-email {
  width: calc(100% - var(--margin-spacing));
}
.btn-secondary {
  background-color: transparent !important;
  border-color: transparent !important;
  text-decoration: underline;
  border: none;
  font-weight: 600;
}
.reply-box {
  border-radius: 5px;
  background-color: var(--theme-secondary-color);
  border-color: var(--theme-secondary-color);
  margin-bottom: 10px;

  color: red;
}
.panel-bg-file-img {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding-bottom: 100%;
  border: 2px dashed #979797;
  width: 100%;
  cursor: move;
  background-color: white;
}
.title-image {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: bold;
}
.reply-text {
  color: var(--primary-color);
  font-size: 16px;
}
input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
  width: 100%;
  height: 100%;
  line-height: 95px;
  position: absolute;
  opacity: 0;
}
.icon-add {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 25px;
  height: 25px;
}
</style>
